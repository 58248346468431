body {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Larger spacing between an h1 and the previous section's body text */
h1, h2 {
  margin-bottom: 0;
  margin-top: 30px;
}

/* Fixes an issue with misaligned text in responses */
strong {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

/* Hide white-labeling */
div a[href="https://redocly.com/redoc/"] {
  display: none !important;
}

/** API endpoint tags */
.tag {
  border-radius: 4px;
  color: white;
  display: inline-block;
  font-weight: 600; /* Semi-bold */
  padding: 0.25em 0.5em;
}
.tag[data-color="blue"] {
  background-color: #61A8FD;
}
.tag[data-color="red"] {
  background-color: #E23730;
}
.tag[data-color="yellow"] {
  background-color: #F5BB00;
}
.tag[data-color="grey"] {
  background-color: rgba(38,50,56,0.5);
}

/*
  Hack to replace "required" with "always" for object schemas.
  The "required" verbiage makes it seem like these fields must be provided on a create action, though
  that's not necessarily the case - it just means the server will always return the field.
 */
[data-section-id] td[kind="field"] div {
  font-size: 0;
}
[data-section-id] td[kind="field"] div:after {
  color: green;
  content: "always";
  font-size: 11.7px;
}

/*
  Prevents the above hack from modifying the request body of operations, since an update to Redoc
  nested them under a [data-section-id^="tag"] element.
 */
[data-section-id] [data-section-id^="operation"] td[kind="field"] div {
  font-size: 11.7px;
}
[data-section-id] [data-section-id^="operation"] td[kind="field"] div:after {
  font-size: 0;
}
